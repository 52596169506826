<template>
  <product-form
    :is-edit="true"
    :productId="this.$route.query.id"
  ></product-form>
</template>
<script>
import ProductForm from './ProductForm';

export default {
  name: 'addProduct',
  components: { ProductForm },
};
</script>
<style>
</style>
